export class DefectEdit {
	DOM = {};
	DEFECT_DATA = {};
	
	init() {
		if(app.CACHE.USR.opr_priv_app_vehicle_defects === '0' || app.CACHE.USR.priv_app_vehicle_defects === '0') {
			app.redirect('home');
			return;
		}
		
		// Redirect back to the list if the defect data isn't present
		if(!app.DATA.DEFECT) {
			app.redirect(`live_defects_list/${app.URI[1]}`);
		}
		this.DEFECT_DATA = app.DATA.DEFECT;
		
		this.setupDOM();
		this.loadData();
	}
	
	setupDOM() {
		this.DOM.form_btn_update = app.DOM.content_load.find('#btn-update');
		this.DOM.status_change_notes = app.DOM.content_load.find('#status-change-notes');
		this.DOM.status_notes_wrapper = app.DOM.content_load.find('#status-notes-wrapper');
		this.DOM.edit_status = app.DOM.content_load.find('#edit-status');
		
		this.DOM.loading = app.DOM.content_load.find('#loading');
		this.DOM.load_error = app.DOM.content_load.find('#load-error');
		this.DOM.defect_notes = app.DOM.content_load.find('#defect-notes');
		this.DOM.defect_info = app.DOM.content_load.find('#defect-info');
		this.DOM.loading = app.DOM.content_load.find('#loading');
		this.DOM.load_error = app.DOM.content_load.find('#load-error');
		this.DOM.defect_images = app.DOM.content_load.find('#defect-images');
		this.DOM.defect_img_container = app.DOM.content_load.find('#img-container');
		this.DOM.driver_notes = app.DOM.content_load.find('#driver-notes');
		this.DOM.defect_notes = app.DOM.content_load.find('#defect-notes');
	}
	
	loadData() {
		if(!app.NETWORK.STATUS) {
			this.DOM.loading.hide();
			this.DOM.defect_info.hide();
			this.DOM.defect_notes.hide();
			this.DOM.load_error.show();
			return;
		}
		
		$.ajax({
			url: app.get_api_url('get_defect'),
			data: app.get_api_data({defect_id: app.DATA.DEFECT.id, defect_tbl: app.DATA.DEFECT.tbl}),
			method: 'POST'
		}).done((res) => {
			if(res.status === 'success') {
				if(res.data && res.data.defect) {
					this.DEFECT_DATA = res.data.defect;
					this.DEFECT_DATA.tbl = app.DATA.DEFECT.tbl;
					this.createStatusEditContainer(res.data.defect);
					this.updateDefectNotes();
					app.setup_footer([
						{ btn:'left', txt: 'Back', href: `home` },
						{ btn: 'right', txt: 'Update', click: 'form_btn_update' }
					]);
					this.DOM.edit_status.show();
					this.setupStatusEvents();
					
					this.DOM.loading.hide();
					this.DOM.defect_info.show();
					this.DOM.defect_notes.show();
					return;
				} else {
					app.setup_footer([
						{ btn:'left', txt: 'Back', href: `home` }
					]);
					this.DOM.edit_status.hide();
				}
			}
			
			this.DOM.loading.hide();
			this.DOM.defect_info.hide();
			this.DOM.defect_notes.hide();
			this.DOM.load_error.show();
		}).catch(() => {
			this.DOM.loading.hide();
			this.DOM.defect_info.hide();
			this.DOM.defect_notes.hide();
			this.DOM.load_error.show();
		});
	}
	
	setupStatusEvents() {
		if(!this.DEFECT_DATA) {
			return;
		}
		
		this.DOM.form_btn_update.unbind('click').on('click', () => {
			const defectStatusEl = app.VIEW.defect_edit.DOM.edit_status.find('input[name="status"]:checked');
			const notes = app.VIEW.defect_edit.DOM.status_change_notes.val();
			if(!notes || notes.length === 0 || !this.validStatusHasChanged()) {
				app.DOM.footer_btn_right.addClass('button-grey').removeClass('button-primary');
				return;
			}
			
			app.DOM.footer_btn_right.addClass('button-primary').removeClass('button-grey');
			this.updateDefectStatus(defectStatusEl.val(), notes);
		});
		
		
		this.DOM.status_change_notes.unbind('keyup').on('keyup', (e) => {
			if(e.currentTarget.value && e.currentTarget.value.length > 0 && this.validStatusHasChanged()) {
				app.DOM.footer_btn_right.addClass('button-primary').removeClass('button-grey');
				app.VIEW.defect_edit.DOM.status_change_notes.removeClass('error');
				return;
			}
			
			app.VIEW.defect_edit.DOM.status_change_notes.addClass('error');
			app.DOM.footer_btn_right.addClass('button-grey').removeClass('button-primary');
		});
		
		app.DOM.content_load.find('input').unbind('change').on('change', (e) => {
			const notes = app.VIEW.defect_edit.DOM.status_change_notes.val();
			const newType = $(e.currentTarget).val();
			
			if(!notes || notes.length === 0 || !this.validStatusHasChanged()) {
				app.DOM.footer_btn_right.addClass('button-grey').removeClass('button-primary');
				app.VIEW.defect_edit.DOM.status_notes_wrapper.show();
				if(
					(newType === '0' && this.DEFECT_DATA.is_resolved !== '1' && this.DEFECT_DATA.is_monitoring !== '1' && this.DEFECT_DATA.is_pending !== '1') ||
					(newType === '1' && this.DEFECT_DATA.is_resolved === '1' && this.DEFECT_DATA.is_monitoring !== '1' && this.DEFECT_DATA.is_pending !== '1') ||
					(newType === '2'&& this.DEFECT_DATA.is_resolved !== '1' && this.DEFECT_DATA.is_monitoring !== '1' && this.DEFECT_DATA.is_pending === '1') ||
					(newType === '3'&& this.DEFECT_DATA.is_resolved !== '1' && this.DEFECT_DATA.is_monitoring === '1' && this.DEFECT_DATA.is_pending !== '1')
				) {
					app.VIEW.defect_edit.DOM.status_change_notes.removeClass('error');
					app.VIEW.defect_edit.DOM.status_notes_wrapper.hide();
				} else {
					app.VIEW.defect_edit.DOM.status_change_notes.addClass('error');
					app.VIEW.defect_edit.DOM.status_notes_wrapper.show();
				}
				
				return;
			}
			
			app.DOM.footer_btn_right.addClass('button-primary').removeClass('button-grey');
		});
		app.DOM.footer_btn_right.addClass('button-grey').removeClass('button-primary');
	}
	
	createStatusEditContainer(defect) {
		let unresolvedExtra = defect.is_resolved === '0' && defect.is_pending === '0' && defect.is_monitoring === '0' ? 'checked ' : '',
			pendingExtra = defect.is_pending === '1' ? 'checked ' : '',
			monitoringExtra = defect.is_monitoring === '1' ? 'checked ' : '',
			monitoringLabelExtra = '',
			resolvedExtra = defect.is_resolved === '1' ? 'checked ' : '';
		
		if(defect.tbl === 'rep_ans') {
			if(defect.id_similar === '1') {
				monitoringExtra += 'disabled ';
				monitoringLabelExtra += 'title="Cannot change to monitored as this is part of a series of Known Defects" ';
			}
			
			if(defect.is_safety === '1' && defect.is_monitoring !== '1') {
				monitoringExtra += 'disabled ';
				monitoringLabelExtra += `title="Cannot change to \"Monitored\" as this is a Safety Fail" `;
			}
		}
		const unresolved = `
			<input type="radio" id="status_unresolved" name="status" value="0" ${unresolvedExtra}>
            <label style="width:100%;" class="left-solid radio-red-solid" for="status_unresolved">Unresolved</label>
		`;
		
		const html = `
	        <span class="container" style="width:100%;">
	        	${!defect.health || defect.health !== '5' ? unresolved : ''}
	            <input type="radio" id="status_pending" name="status" value="2" ${pendingExtra}>
            	<label style="width:100%;" class="centre-solid radio-blue" for="status_pending">Pending</label>
	            <input type="radio" id="status_monitoring" name="status" value="3" ${monitoringExtra}>
	            <label style="width:100%;" class="centre-solid radio-orange" for="status_monitoring" ${monitoringLabelExtra}>Monitoring</label>
	            <input type="radio" id="status_resolved" name="status" value="1" ${resolvedExtra}>
	            <label style="width:100%;" class="right-solid radio-green-solid" for="status_resolved">Resolved</label>
	        </span>
	    `;
		this.DOM.edit_status.find('.radios').html(html);
		this.DOM.edit_status.show();
	}
	
	updateDefectNotes() {
		this.DOM.defect_images.html(Handlebars.helpers.generateDefectImages(this.DEFECT_DATA));
		const images = this.DOM.defect_images.find('img');
		if(!images || images.length === 0) {
			this.DOM.defect_img_container.find('h3').text('No Photos');
		} else {
			this.DOM.defect_img_container.find('h3').text('Defect Photos:');
		}
		
		if(this.DEFECT_DATA.notes && this.DEFECT_DATA.notes.trim().length > 0) {
			this.DOM.driver_notes.text(this.DEFECT_DATA.notes);
			this.DOM.driver_notes.closest('#driver-notes-container').find('.no-data').hide();
		} else {
			this.DOM.driver_notes.html('');
			this.DOM.driver_notes.closest('#driver-notes-container').find('.no-data').show();
		}
		
		if(this.DEFECT_DATA.answer && this.DEFECT_DATA.answer.trim().length > 0) {
			this.DOM.defect_notes.html(Handlebars.helpers.formatDefectNotes(this.DEFECT_DATA.answer));
			this.DOM.defect_notes.closest('#defect-notes-container').find('.no-data').hide();
		} else {
			this.DOM.defect_notes.html('');
			this.DOM.defect_notes.closest('#defect-notes-container').find('.no-data').show();
		}
	}
	
	validStatusHasChanged() {
		let defectStatusEl = app.VIEW.defect_edit.DOM.edit_status.find('input[name="status"]:checked');
		if(!defectStatusEl) {
			app.DOM.footer_btn_right.addClass('button-grey').removeClass('button-primary');
			return false;
		}
		
		if(
			(this.DEFECT_DATA.is_resolved === '1' && defectStatusEl.val() === '1') ||
			(this.DEFECT_DATA.is_monitoring === '1' && defectStatusEl.val() === '3') ||
			(this.DEFECT_DATA.is_pending === '1' && defectStatusEl.val() === '2') ||
			(this.DEFECT_DATA.is_resolved === '0' && this.DEFECT_DATA.is_monitoring === '0' && this.DEFECT_DATA.is_pending === '0' && defectStatusEl.val() === '0')
		) {
			app.DOM.footer_btn_right.addClass('button-grey').removeClass('button-primary');
			return false;
		}
		
		app.DOM.footer_btn_right.addClass('button-primary').removeClass('button-grey');
		return true;
	}
	
	getOriginalStatus() {
		if(this.DEFECT_DATA.is_resolved === '1') {
			return '1';
		}
		
		if(this.DEFECT_DATA.is_monitoring === '1') {
			return '3';
		}
		
		if(this.DEFECT_DATA.is_pending === '1') {
			return '2';
		}
		
		if(this.DEFECT_DATA.is_resolved === '0') {
			return '0';
		}
	}
	
	updateDefectStatus(status, notes) {
		if(!app.NETWORK.STATUS) {
			app.show_alert('Unable to update defects without an internet connection.', 'Network Error');
			return;
		}
		
		$.ajax({
			url: app.get_api_url('update_defect'),
			data: app.get_api_data({
				defect_id: this.DEFECT_DATA.id,
				defect_tbl: this.DEFECT_DATA.tbl,
				vehicle_id: app.URI[1],
				operator_id: app.CACHE.USR.operator_id,
				question_id: this.DEFECT_DATA.report_question_id,
				is_resolved: status,
				is_resolved_original: this.getOriginalStatus(),
				notes_resolved: notes
			}),
			method: 'POST'
		}).done((res) => {
			if(res.status === 'success') {
				app.redirect(`live_defects_list/${app.URI[1]}`);
				app.show_alert('Defect status updated successfully', '');
			} else {
				app.setup_footer([{ btn:'left', txt: 'Back', href: `home` }]);
				this.DOM.edit_status.hide();
				app.show_alert('Something went wrong when updating this defects at this time.', 'Defect Update Error');
			}
		}).catch(() => {
			app.show_alert('Unable to update defects without an internet connection.', 'Network Error');
		});
	}
}