export class DefectView {
	DOM = {};
	DEFECT_DATA = {};
	
	init() {
		if(app.CACHE.USR.opr_priv_app_vehicle_defects === '0' || app.CACHE.USR.priv_app_vehicle_defects === '0') {
			app.redirect('home');
			return;
		}
		
		// Redirect back to the list if the defect data isn't present
		if(!app.DATA.DEFECT) {
			app.redirect(`live_defects_list/${app.URI[1]}`);
		}
		
		this.setupDOM();
		this.loadData();
		app.setup_footer([{ btn:'left', txt: 'Back', href: `home` }]);
	}
	
	setupDOM() {
		this.DOM.loading = app.DOM.content_load.find('#loading');
		this.DOM.load_error = app.DOM.content_load.find('#load-error');
		this.DOM.defect_images = app.DOM.content_load.find('#defect-images');
		this.DOM.defect_img_container = app.DOM.content_load.find('#img-container');
		this.DOM.driver_notes = app.DOM.content_load.find('#driver-notes');
		this.DOM.defect_notes = app.DOM.content_load.find('#defect-notes');
		this.DOM.defect_header = app.DOM.content_load.find('#defect-header');
	}
	
	loadData() {
		if(!app.NETWORK.STATUS) {
			this.DOM.load_error.show();
			this.DOM.loading.hide();
			this.DOM.defect_notes.hide()
			this.DOM.defect_header.hide();
			return;
		}
		
		$.ajax({
			url: app.get_api_url('get_defect'),
			data: app.get_api_data({defect_id: app.DATA.DEFECT.id, defect_tbl: app.DATA.DEFECT.tbl}),
			method: 'POST'
		}).done((res) => {
			if (res.status === 'success') {
				if (res.data && res.data.defect) {
					this.DEFECT_DATA = res.data.defect;
					this.DEFECT_DATA.tbl = app.DATA.DEFECT.tbl;
					
					this.DOM.defect_images.html(Handlebars.helpers.generateDefectImages(this.DEFECT_DATA));
					const images = this.DOM.defect_images.find('img');
					if(!images || images.length === 0) {
						this.DOM.defect_img_container.find('h3').text('No Photos');
					} else {
						this.DOM.defect_img_container.find('h3').text('Defect Photos:');
					}
					
					if(this.DEFECT_DATA.notes && this.DEFECT_DATA.notes.trim().length > 0) {
						this.DOM.driver_notes.text(this.DEFECT_DATA.notes);
						this.DOM.driver_notes.closest('#driver-notes-container').find('.no-data').hide();
					} else {
						this.DOM.driver_notes.html('');
						this.DOM.driver_notes.closest('#driver-notes-container').find('.no-data').show();
					}
					
					if(this.DEFECT_DATA.answer && this.DEFECT_DATA.answer.trim().length > 0) {
						this.DOM.defect_notes.html(Handlebars.helpers.formatDefectNotes(this.DEFECT_DATA.answer));
						this.DOM.defect_notes.closest('#defect-notes-container').find('.no-data').hide();
					} else {
						this.DOM.defect_notes.html('');
						this.DOM.defect_notes.closest('#defect-notes-container').find('.no-data').show();
					}
					
					this.DOM.load_error.hide();
					this.DOM.loading.hide();
					this.DOM.defect_notes.show()
					this.DOM.defect_header.show();
					return;
				}
			}
			
			this.DOM.load_error.show();
			this.DOM.loading.hide();
			this.DOM.defect_notes.hide()
			this.DOM.defect_header.hide();
		}).catch(() => {
			this.DOM.load_error.show();
			this.DOM.loading.hide();
			this.DOM.defect_notes.hide()
			this.DOM.defect_header.hide();
		});
	}
}